<template>
  <div class="order-base__warp">
    <div class="erp-form-bar bar-sticky" style="top: 0">
      <el-button size="small" type="primary" class="erp-primary__btn" @click="audit">提交</el-button>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <!-- 55等分布局 -->
    <el-row :gutter="0" type="flex" class="op-16">
      <el-col :span="12" style="margin-right: 80px">
        <div class="erp-form__title f16">审核收款订单</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="付方名称" :value="detailsData.payerName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="交易时间" :value="detailsData.transactionTime"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="收款金额" :value="detailsData.amountDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="收款方名称" :value="detailsData.principalFullName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="未关联收款金额" :value="detailsData.writtenOffAmountDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="摘要" :value="detailsData.remark"></erp-view>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!--订单产品信息展示-->
    <div style="margin-top: 30px" class="order-title__bar op-16">
      <div class="erp-form__title f16">收款订单</div>
      <div class="erp-product__count">
        <span class="count-name">创建时间：</span>
        <span class="count-value" style="margin-right: 16px">{{ detailsData.waitAudit.createdTime }}</span>
        <span class="count-name">创建人：</span>
        <span class="count-value">{{ detailsData.waitAudit.creator }}</span>
      </div>
    </div>
    <div class="claim_company">
      <el-table :data="auditList" border class="egrid erp-table__list erp-view__table">
        <el-table-column label="销售合同号" prop="plantNo" align="center" show-overflow-tooltip width="180" class-name="no-pd">
          <template #default="scope">
            <template v-if="!scope.row.isLast">
              <div class="record__link" @click="$showFiles(scope.row.contractUrlName, scope.row.contractUrl)" v-if="!!scope.row.businessContractNo">
                {{ filterName(scope.row.businessContractNo) }}
              </div>
              <div v-else>-</div>
            </template>
            <template v-else>
              <div>合计</div>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="签约主体" prop="contractPrincipalName" align="center" show-overflow-tooltip width="180" class-name="no-pd">
          <template #default="scope">
            <div>{{ filterName(scope.row.contractPrincipalName) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="收款类型" prop="nameCn" align="center" show-overflow-tooltip width="100" class-name="no-pd">
          <template #default="scope">
            <div>{{ filterName(scope.row.infundTypeDesc) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="客户" prop="nameEn" align="center" show-overflow-tooltip width="180" class-name="no-pd">
          <template #default="scope">
            <div>{{ filterName(scope.row.customerName) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="销售结算单号" align="center" show-overflow-tooltip width="130" class-name="no-pd">
          <template #default="scope">
            <template v-if="!scope.row.isLast">
              <div class="record__link" v-if="!!scope.row.sellStmtNo" @click="$showFiles(scope.row.excelName, scope.row.excelUrl)">
                {{ filterName(scope.row.sellStmtNo) }}
              </div>
              <div v-else>-</div>
            </template>
            <template v-else>
              <div></div>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="杂费类型" prop="varietyName" align="center" show-overflow-tooltip width="100" class-name="no-pd">
          <template #default="scope">
            <div>{{ filterName(scope.row.sundryFeeTypeDesc) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="结算金额" prop align="center" show-overflow-tooltip width="100" class-name="no-pd">
          <template #default="scope">
            <div>{{ filterName(scope.row.expectAmountDesc) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="核销金额" prop align="center" show-overflow-tooltip width="100" class-name="no-pd">
          <template #default="scope">
            <div>{{ filterName(scope.row.actualAmountDesc) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop align="center" show-overflow-tooltip class-name="no-pd">
          <template #default="scope">
            <div>{{ filterName(scope.row.remark) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" prop align="center" width="168" class-name="no-pd">
          <template #default="scope">
            <template v-if="!scope.row.isLast">
              <div>
                <el-radio-group v-model="scope.row.auditStatus" class="erp-radio">
                  <el-radio :label="2">通过</el-radio>
                  <el-radio :label="3">不通过</el-radio>
                </el-radio-group>
              </div>
            </template>
            <template v-else>
              <div class="" style="display: flex; justify-content: space-between; padding: 0 14px">
                <div class="record__link" @click="setAuditStatus(2)">全通过</div>
                <div class="record__link" @click="setAuditStatus(3)">全不通过</div>
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="审核说明" prop align="center" class-name="no-pd" width="280">
          <template #default="scope">
            <el-input v-model="scope.row.auditDesc" placeholder :maxlength="30"></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getRouterParams } from '@/utils/publicMethods';
export default {
  name: 'collectionAuditDetail',
  components: {},
  props: {
    id: {
      // 获取传递过来的详情
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      detailsData: {
        amount: null,
        amountDesc: null,
        confirmList: [],
        flowNo: null,
        isBusinessRelated: false,
        otherBusnam: null,
        otherBusnar: null,
        otherChknbr: null,
        otherNusage: null,
        otherReqnbr: null,
        otherTrscod: null,
        otherYurref: null,
        payerAccountArea: null,
        payerAccountName: null,
        payerBankAddress: null,
        payerBankName: null,
        payerBankNo: null,
        payerName: null,
        remark: null,
        transactionTime: null,
        waitAudit: {
          listVo: [],
        },
        writeOffStatus: null,
        writtenOffAmount: null,
        writtenOffAmountDesc: null,
      },
      infundId: null,
      version: null,
      visible: false, // 侧栏弹出显示
      backPath: null, // 返回列表
      auditList: [], // 自定义展示审核列
      globalAuditStatus: 0,
    };
  },
  methods: {
    setAuditList(data) {
      // 设置自定义列
      if (data && data.length === 1) {
        return data;
      } else {
        // 制作合计行
        let expectAmountTotal = data.reduce((total, item) => {
          const price = Number(item.expectAmount);
          return total + price;
        }, 0);

        let actualAmountTotal = data.reduce((total, item) => {
          const price = Number(item.actualAmount);
          return total + price;
        }, 0);
        actualAmountTotal = this._formatFloat(actualAmountTotal, 2);
        expectAmountTotal = this._formatFloat(expectAmountTotal, 2);

        if (isNaN(expectAmountTotal)) {
          expectAmountTotal = 0;
        }
        if (isNaN(actualAmountTotal)) {
          actualAmountTotal = 0;
        }
        const lastData = {
          isLast: true,
          actualAmountDesc: this._thousandBitSeparator(actualAmountTotal),
          expectAmountDesc: this._thousandBitSeparator(expectAmountTotal),
        };
        data.push(lastData);
        return data;
      }
    },
    setAuditStatus(value) {
      // 审核全通过
      this.auditList.forEach((v, i) => {
        this.auditList[i].auditStatus = value;
      });
    },
    getCollectionData(infundId) {
      // 获取列表信息
      this.ajax({
        type: 'GET',
        url: `/malicrm/fmInfund/queryFmInfundDetailById/${infundId}`,
        success: (res) => {
          if (res.code === 200) {
            this.detailsData = res.result;
            this.auditList = this.setAuditList(res.result.waitAudit.listVo);
            this.version = res.result.version;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    back() {
      // 返回
      this.$router.push({
        name: this.backPath, // 查看订单详情路由对象名字 来源/src/router/erp/router.js
      });
    },

    validateData() {
      const data = this.auditList;
      let status = true;
      const len = data.length > 1 ? data.length - 1 : data.length;
      for (let i = 0; i < len; i++) {
        const item = data[i];
        if (item.auditStatus !== 2 && item.auditStatus !== 3) {
          this.errorTip('请进行审核操作');
          status = false;
          break;
        }
        if (item.auditStatus === 3 && !item.auditDesc) {
          this.errorTip('审核不通过请说明原因');
          status = false;
          break;
        }
      }

      let actualAmountTotal = data.reduce((total, item) => {
        const price = Number(item.actualAmount);
        return total + price;
      }, 0);
      actualAmountTotal = this._formatFloat(actualAmountTotal, 2);
      if (actualAmountTotal > this.detailsData.writtenOffAmount) {
        status = false;
        this.errorTip('合计核销金额大于未关联收款金额');
      }
      return status;
    },
    audit() {
      const status = this.validateData();
      if (status) {
        const infundAudit = [];
        const item = this.auditList;
        const len = item.length > 1 ? item.length - 1 : item.length;
        item.forEach((v, i) => {
          if (i < len) {
            const data = {
              auditDesc: v.auditDesc,
              auditStatus: v.auditStatus,
              id: v.id,
            };
            infundAudit.push(data);
          }
        });
        const params = {
          infundAudit,
          infundId: this.infundId,
          version: this.version,
        };
        this.ajax({
          type: 'POST',
          url: '/malicrm/fmInfund/auditInfund',
          data: params,
          success: (res) => {
            if (res.code === 200) {
              this.back();
            } else {
              this.errorTip(res.message);
            }
          },
        });
      }
    },
  },
  created() {
    const { id, version, path } = getRouterParams('collectionAuditDetail');
    this.infundId = id;
    this.version = version;
    this.backPath = path; // 返回列表还是返回详情
    this.getCollectionData(id);
  },
};
</script>

<style lang="scss" scoped>
.order-title__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}
.count-value {
  font-size: 14px;
  color: #303133;
}
.count-name {
  font-size: 14px;
  color: #606266;
}
</style>
